import React from "react";
import Layout from "../../../components/Layout";
import Section from "../../../components/Section";
import LuckyGuide from "../../../components/LuckyGuide";
import ReadMoreBlock from "../../../components/ReadMoreBlock";
import useDeviceWidthCheck from "../../../hooks/useDeviceWidthCheck";
import { section as luckyGuideSection } from "../../../components/LuckyGuide/luckyguide.module.scss";
import { section as readMoreBlockSection } from "../../../components/ReadMoreBlock/readMoreBlock.module.scss";
import { linkButton } from "./homepage.module.scss";
import { noSidePadding } from "../../../components/Section/section.module.scss";

const Homepage = ({
    pageContext: {
        luckyGuide
    }
}) => {
    const isDesktop = useDeviceWidthCheck();

    return (
        <Layout>
            <Section sectionClass={'text-center'}>
                <p>The best online Fafi Dream Guide.</p>
                <p>Use our Fafi Dream Guide to interpret your dreams. Discover your lucky numbers now!</p>
                <a
                    href="https://wa.me/27725035810/?text=help"
                    className={linkButton}
                    target='_blank'
                    rel="noreferrer"
                >
                    Get prediction
                </a>
            </Section>
            {
                Array.isArray(luckyGuide) &&
                <Section sectionClass={luckyGuideSection} containerClass={noSidePadding}>
                    <LuckyGuide luckyGuide={luckyGuide} isDesktop={isDesktop}/>
                </Section>
            }
            <Section sectionClass={`${readMoreBlockSection}`} containerClass={noSidePadding}>
                <ReadMoreBlock isDesktop={isDesktop}/>
            </Section>
        </Layout>
    );
};

export default Homepage;
