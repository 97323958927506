import React, { useState, useEffect } from "react";
import { wrapper, loadmore, open } from "./readMoreBlock.module.scss";

const DESKTOP_SHORT_TEXT = 'There are many classic online games that originated from traditional real-life games played in different parts of the world. Various betting games have been present in all cultures, as they offer quite the adrenaline rush and everyone hopes that they will be the one to win. The same goes for a game called Fafi, or otherwise referred to as mo-china. On this site, you can easily play this game online! Fafi takes its beginnings in the Chinese community of South Africa and is similar to a lotto draw where you have to pick numbers and wait for them to be drawn in order to win...';
const MOBILE_SHORT_TEXT = 'There are many classic online games that originated from traditional real-life games played in different parts of the world...';

const ReadMoreBlock = ({ isDesktop }) => {
    const [isMore, setIsMore] = useState(false);
    const [shortText, setShortText] = useState(MOBILE_SHORT_TEXT);

    useEffect(() => {
        if ( isDesktop ) {
            setShortText(DESKTOP_SHORT_TEXT);
        } else {
            setShortText(MOBILE_SHORT_TEXT);
        }
    }, [isDesktop]);

    const clickReadMoreHandler = () => setIsMore(true);

    return (
        <>
            <div className={`${wrapper} ${isMore ? open : ''}`}>
                {
                    isMore ?
                        <>
                            <p>There are many classic online games that originated from traditional real-life games played in different parts of the world. Various betting games have been present in all cultures, as they offer quite the adrenaline rush and everyone hopes that they will be the one to win. The same goes for a game called Fafi, or otherwise referred to as mo-china. On this site, you can easily play this game online! Fafi takes its beginnings in the Chinese community of South Africa and is similar to a lotto draw where you have to pick numbers and wait for them to be drawn in order to win. The main trick in Fafi is how closely it is connected to the dreams that you have at night, as they play a crucial role in what numbers you will use in your bets. It’s a relief that you don’t have to decide on numbers. It’s all up to fate, right from the start. Fafi is fun and exhilarating – you won’t be able to stop playing.</p>
                            <p>The game has a rich history and it was traditionally played by men and women living in South Africa. This betting game is quite simple when it comes to the rules. The picking of numbers for gambling is based on interpreting dreams rather than coming up with them randomly.</p>
                            <p>There exist different systems that help convert symbols that occur in dreams into actual numbers. For instance, having a ship occur in your dream may give you the number 3 while dreaming about someone robbing you gives you the number 7. Each game requires a runner who takes bets. The numbers are written down and put in a bag along with a person’s name and money. Once the bag is filled with bets, it’s given to one of the organisers. They choose the winning number and whisper the winner’s name to the runner who then pays out the sum to the lucky winner.</p>
                        </>
                        :
                        <p>{shortText}</p>
                }
            </div>
            {
                !isMore && <button className={loadmore} onClick={clickReadMoreHandler}>Read more</button>
            }
        </>
    );
};

export default ReadMoreBlock;
