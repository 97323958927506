import React from "react";
import Header from "../Header";
import Footer from "../Footer";

const Layout = ({ children, customClass = '' }) => (
    <>
        <div>
            <Header/>
            <main className={customClass}>
                {children}
            </main>
        </div>
        <Footer/>
    </>
);

export default Layout;
