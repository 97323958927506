import React from "react";
import headerMobileSvg from "../../images/header.svg";
import headerDesktopSvg from "../../images/header-desktop.svg";
import { header as headerClass, bigImg, smallImg } from "./header.module.scss";

const Header = () => (
    <header className={headerClass}>
        <img src={headerMobileSvg} className={smallImg} alt="header"/>
        <img src={headerDesktopSvg} className={bigImg} alt="header"/>
    </header>
);

export default Header;
