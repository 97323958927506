import { useState, useEffect } from "react";

const useDeviceWidthCheck = () => {
    const [isDesktop, setIsDesktop] = useState(false);

    useEffect(() => {
        const resizeHandler = () => {
            if ( window.innerWidth > 1023 ) {
                setIsDesktop(true);
            } else {
                setIsDesktop(false);
            }
        };
        resizeHandler();
        window.addEventListener('resize', resizeHandler);

        return () => window.removeEventListener('resize', resizeHandler);
    }, []);

    return isDesktop;
};

export default useDeviceWidthCheck;
