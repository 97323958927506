import React from "react";
import { container, defaultSection } from './section.module.scss';

const Section = ({ children, sectionClass = '', containerClass = '', isContainerExists = true }) => (
    <section className={`${defaultSection} ${sectionClass}`}>
        {
            isContainerExists ?
                <div className={`${container} ${containerClass}`}>
                    {children}
                </div>
                :
                {children}
        }
    </section>
);

export default Section;
