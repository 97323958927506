import React, { useState, useEffect } from "react";
import Row from "./Row";
import { wrapper, loadmore } from "./luckyguide.module.scss";

const DESKTOP_VIEW_ITEMS_COUNT = 8;
const MOBILE_VIEW_ITEM_COUNT = 6;

const LuckyGuide = ({ luckyGuide, isDesktop = false }) => {
    const [viewCount, setViewCount] = useState(MOBILE_VIEW_ITEM_COUNT);
    const [viewItemsCount, setViewItemsCount] = useState(MOBILE_VIEW_ITEM_COUNT);

    useEffect(() => {
        if ( isDesktop ) {
            setViewCount(DESKTOP_VIEW_ITEMS_COUNT);
            setViewItemsCount(DESKTOP_VIEW_ITEMS_COUNT);
        } else {
            setViewCount(MOBILE_VIEW_ITEM_COUNT);
            setViewItemsCount(MOBILE_VIEW_ITEM_COUNT);
        }
    }, [isDesktop]);

    const viewMoreItemsHandler = () => setViewCount(viewCount + viewItemsCount);

    return (
        <>
            <div className={wrapper}>
                {
                    luckyGuide.slice(0, viewCount).map((guideItem, index) => (
                        <Row key={index} number={index + 1} words={guideItem} />
                    ))
                }
            </div>
            {
                viewCount < luckyGuide.length &&
                <button type="button" className={loadmore} onClick={viewMoreItemsHandler}>
                    Show more
                </button>
            }
        </>
    );
};

export default LuckyGuide;
