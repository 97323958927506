import React from "react";
import {row, left, right} from "../luckyguide.module.scss";

const Row = ({ number, words }) => (
    <div className={row}>
        <p className={left}>
            {number}
        </p>
        <p className={right}>
            {words}
        </p>
    </div>
);

export default Row;
